<template>
  <div class="base-list-page">
    <el-form
      :model="searchForm"
      @keyup.enter.native="getDataList(1)"
      ref="dataForm"
      inline
      class="bysearchForm"
    >
      <div class="searchLine">
        <el-form-item label="公司" class="typeInput">
          <el-select
            v-model="searchForm.companyIds"
            multiple
            filterable
            clearable
          >
            <el-option
              v-for="(item, index) in companyList"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="发放类型" class="typeInput">
          <el-select
            v-model="searchForm.distributionType"
            placeholder="发放类型"
            clearable
          >
            <el-option key="0" label="发至员工账户" value="0" />
            <el-option key="1" label="直接划扣" value="1" />
          </el-select>
        </el-form-item>
        <el-form-item label="发放状态" class="typeInput">
          <el-select
            v-model="searchForm.arriveStatus"
            placeholder="发放状态"
            clearable
            multiple
          >
            <el-option key="0" label="发放成功/划扣成功" value="0" />
            <el-option key="1" label="余额不足" value="1" />
            <el-option key="2" label="退回成功" value="2" />
          </el-select>
        </el-form-item>
        <el-form-item label="发放时间" class="typeInput">
          <el-date-picker
            v-model="date"
            class="el-popper DatepickerTime"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            clearable
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="订单编号" class="typeInput">
          <el-input
            v-model="searchForm.orderId"
            placeholder="订单编号"
            clearable
          />
        </el-form-item>
        <el-form-item label="补助类型" class="typeInput">
          <el-select
            v-model="searchForm.subsidyType"
            placeholder="补助类型"
            clearable
          >
            <el-option key="1" label="按天补助" value="1" />
            <el-option key="2" label="按月补助" value="2" />
          </el-select>
        </el-form-item>
        <el-form-item class="typeInput">
          <el-button @click="getDataList(1)">查询</el-button>
          <el-button type="primary" size="mini" @click="openReport()"
            >导出报表</el-button
          >
        </el-form-item>
      </div>
    </el-form>
    <h4>费用总览</h4>
    <div class="listBtn">
      <span
        >发放补助金额总计<b>{{ this.totalList.subsidyTotal }}</b></span
      >
      <span
        >发至员工账户的补助总计<b>{{ this.totalList.employeesTotal }}</b></span
      >
      <span
        >直接划扣的补助总计<b>{{ this.totalList.deductedTotal }}</b></span
      >
      <span
        >直接划扣的补助总计（划扣成功）<b>{{
          this.totalList.successDeducted
        }}</b></span
      >
      <span
        >直接划扣的补助总计（退回成功）<b>{{
          this.totalList.refundDeducted
        }}</b></span
      >
    </div>
    <el-table
      :data="dataList"
      border
      stripe
      v-loading="dataListLoading"
      ref="dataList"
      @selection-change="selectionChangeHandle"
    >
      <el-table-column
        prop="orderId"
        header-align="center"
        align="center"
        label="订单编号"
      >
      </el-table-column>
      <el-table-column
        prop="companyName"
        header-align="center"
        align="center"
        label="公司名称"
      >
      </el-table-column>
      <el-table-column
        prop="employeesNum"
        header-align="center"
        align="center"
        label="员工人数"
      >
      </el-table-column>
      <el-table-column
        prop="allocatedNum"
        header-align="center"
        align="center"
        label="实际发放人数"
      >
      </el-table-column>
      <el-table-column
        prop="subsidyType"
        header-align="center"
        align="center"
        label="补助类型"
      >
      </el-table-column>
      <el-table-column
        prop="subsidyMoney"
        header-align="center"
        align="center"
        label="发放补助金额"
      >
      </el-table-column>
      <el-table-column
        prop="time"
        header-align="center"
        align="center"
        label="发放时间"
      >
      </el-table-column>
      <el-table-column
        prop="distributionTypeName"
        header-align="center"
        align="center"
        label="发放补助类型"
      >
      </el-table-column>
      <el-table-column
        prop="arriveStatusName"
        header-align="center"
        align="center"
        label="发放状态"
      >
      </el-table-column>
      <el-table-column
        prop="remarks"
        header-align="center"
        align="center"
        label="备注"
      >
      </el-table-column>
    </el-table>
    <list-footer>
      <el-pagination
        slot="right"
        class="right"
        background
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper"
      />
    </list-footer>
  </div>
</template>

<script>
import { listMixin, normal } from '@/mixins';
import { getCompanyList } from '@/utils/options.js';
export default {
  mixins: [listMixin, normal],
  data() {
    return {
      pageSize: 10,
      pageIndex: 1,
      totalPage: 0,
      searchForm: {
        companyIds: [],
        distributionType: '',
        arriveStatus: [],
        startDate: '',
        endDate: '',
        orderId: '',
        subsidyType: '',
      },
      companyList: [],
      totalList: {
        deductedTotal: '',
        employeesTotal: '',
        refundDeducted: '',
        subsidyTotal: '',
        successDeducted: '',
      },
      date: [],
      addOrUpdateVisible: false,
    };
  },
  components: {},
  activated() {
    this.getDataList(1);
  },
  created() {
    this.getCompanyList();
  },
  methods: {
    // 获取数据列表
    getDataList(page) {
      if (this.date?.length) {
        this.searchForm.startDate = this.date[0];
        this.searchForm.endDate = this.date[1];
      } else {
        this.searchForm.startDate = '';
        this.searchForm.endDate = '';
      }
      this.$getDataList({
        url: '/report/company/companySubsidyDistribution',
        params: {
          page: page ? page : this.pageIndex,
          limit: this.pageSize,
          ...this.searchForm,
          companyIds: this.searchForm.companyIds.join(','),
          arriveStatus: this.searchForm.arriveStatus.join(','),
        },
        after: (data) => {
          if (data && data.code === 0) {
            this.dataList = data.page.list;
            this.totalList = data.total;
            this.totalPage = data.page.totalCount;
          } else {
            this.dataList = [];
          }
        },
      });
    },

    getCompanyList() {
      getCompanyList().then(({ data }) => {
        if (data && data.code === 0) {
          this.companyList = data.list;
        }
      });
    },

    openReport() {
      let companyIds = this.searchForm.companyIds.join(',');
      let arriveStatus = this.searchForm.arriveStatus.join(',');
      window.open(`${process.env.VUE_APP_TABLE_BASE_URL}
      company_subsidy_distribution.rptdesign&__showtitle=false&pageOverflow=2&__locale=zh_CN
      &company_ids=${companyIds}&distribution_type=${this.searchForm.distributionType}
      &arrive_status=${arriveStatus}&subsidy_type=${this.searchForm.subsidyType}&order_id=${this.searchForm.orderId}
      &start_date=${this.searchForm.startDate}&end_date=${this.searchForm.endDate}`);
    },
  },
};
</script>
